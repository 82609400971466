import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import {
  useAddressCoverage,
  type AddressCoverageContextType,
} from "../../contexts/address-coverage-demo.context";
import { cn } from "../../utils/functions";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export const pickingSchema = z.object({
  picking: z.object({
    date: z.date({
      required_error: "Veuillez renseigner une date",
      invalid_type_error: "Veuillez renseigner une date valide",
    }),
    timeSlot: z.object(
      {
        id: z.string(),
        label: z.string(),
        startHour: z.number(),
        endHour: z.number(),
      },
      {
        required_error: "Veuillez renseigner un créneau",
        invalid_type_error: "Veuillez renseigner un créneau valide",
      }
    ),
  }),
});

export interface PickingFieldsProps {
  minDate: Date;
  maxDate: Date;
  getSlotsForDate: AddressCoverageContextType["getSlotsForDate"];
}

export function PickingFields({
  minDate,
  maxDate,
  getSlotsForDate,
}: PickingFieldsProps) {
  const { control, watch, setValue } =
    useFormContext<z.infer<typeof pickingSchema>>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [pickingDate, pickingTimeSlot] = watch([
    "picking.date",
    "picking.timeSlot",
  ]);

  const { fetchCoverageByDate, postalCode, getFilteredSlots } =
    useAddressCoverage();

  useEffect(() => {
    (async () => {
      if (!pickingDate || !postalCode) return;
      await fetchCoverageByDate(postalCode, pickingDate);
    })();
  }, [pickingDate, fetchCoverageByDate, postalCode]);

  // Fetch new slots when date changes
  const slotsForSelectedDate = useMemo(() => {
    return !pickingDate ? [] : getFilteredSlots();
  }, [pickingDate, getFilteredSlots]);

  return (
    <div className="flex gap-2">
      <FormField
        control={control}
        name="picking.date"
        render={({ field }) => (
          <FormItem className="flex-1 flex flex-col">
            <FormControl>
              <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "pl-3 text-left font-normal",
                      !pickingDate && "text-muted-foreground"
                    )}
                  >
                    {pickingDate ? (
                      format(pickingDate, "PPP", { locale: fr })
                    ) : (
                      <span>Choisir une date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={pickingDate}
                    onSelect={(value) => {
                      field.onChange(value);
                      setValue("picking.timeSlot", undefined as any);
                      setIsCalendarOpen(false);
                    }}
                    disabled={(date) => {
                      if (date < minDate || date > maxDate) {
                        return true;
                      }
                      const slots = getSlotsForDate(date);
                      return slots.length === 0;
                    }}
                    locale={fr}
                    defaultMonth={pickingDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="picking.timeSlot"
        render={({ field }) => (
          <FormItem className="flex-1">
            <Select
              value={pickingTimeSlot?.id ?? ""}
              onValueChange={(value) => {
                const slot = slotsForSelectedDate.find((s) => s.id === value);
                field.onChange(slot);
              }}
              disabled={!slotsForSelectedDate.length}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Créneau" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {slotsForSelectedDate
                  .sort((a, b) => a.startHour - b.startHour)
                  .map((slot) => (
                    <SelectItem key={slot.id} value={slot.id}>
                      {slot.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
